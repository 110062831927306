import appStart from '../../common/scripts/app';
import aboutSlider from '../../components/about-slider';
import blogSection from '../../components/blog-section';
import frontSlider from '../../components/front-slider';
import mainDirections from '../../components/main-directions';


document.addEventListener('DOMContentLoaded', () => {
    appStart();
    frontSlider();
    aboutSlider();
    mainDirections();
    blogSection();
});
