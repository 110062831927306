import { getScrollTop } from '../../common/scripts/utils';

class Header {
    constructor() {
        this.header = {
            selector: '.j-header',
            showClass: 'is-show',
            blureClass: 'is-blure',
            scrollClass: 'is-scroll',
            hoverMenuClass: 'is-hover-menu',
            theme: { transparent: 'header--transparent' }
        };

        this.headerPremises = {
            selector: '.j-header-premises',
            showClass: 'is-show',
            scrollClass: 'is-scroll'
        };

        this.prevScrollTop = getScrollTop();
        this.scrollTop = getScrollTop();
        this.scrollTop = getScrollTop();
    }

    init() {
        this._initPosition();
        this._initHeaderPremises();
    }

    _initHeaderPremises() {
        this.headerPremises.element = document.querySelector(this.headerPremises.selector);

        if (!this.headerPremises.element) {
            return;
        }

        this.headerPremises.height = this.headerPremises.element.scrollHeight + 20;

        if (this.header) {
            // eslint-disable-next-line no-undef
            if (getNameBreakpoint() === 'mobile') {
                const isScroll = this.scrollTop > this.header.height && this.headerPremises.height <= this.scrollTop;

                if (isScroll) {
                    this._headerPremisesShow();
                } else {
                    this._headerPremisesHide();
                }

                document.addEventListener('scroll', this._handlePageScrollHeaderPremises.bind(this), { passive: true });
            }
        }
    }

    _initPosition() {
        this.header.element = document.querySelector(this.header.selector);

        if (!this.header.element) {
            return;
        }
        this.header.height = this.header.element.scrollHeight + 20;

        document.addEventListener('scroll', this._handlePageScroll.bind(this), { passive: true });
        this.pageLoad();
    }

    pageLoad() {
        this._headerShow();
    }

    _headerPremisesShow() {
        this.headerPremises.element.classList.add(this.headerPremises.showClass);
        this.headerPremises.element.style.transform = `translate3d(0, 0, 0)`;
    }

    _handlePageScroll() {
        this.scrollTop = getScrollTop();

        // eslint-disable-next-line max-len
        this.header.element.classList.toggle(this.header.scrollClass, this.scrollTop > 50 && this.header.height <= this.scrollTop);

        if (this.scrollTop > this.prevScrollTop && this.header.height <= this.scrollTop) {
            this._headerHide();
        } else {
            this._headerShow();
        }

        // if (this.scrollTop == 0) {
        //     this._headerHide();
        // }

        this.prevScrollTop = this.scrollTop <= 0 ? 0 : this.scrollTop;
    }

    _headerPremisesHide() {
        this.headerPremises.element.classList.remove(this.headerPremises.showClass);
        this.headerPremises.element.style.transform = `translate3d(0, -${this.headerPremises.height}px, 0)`;
    }

    _headerHide() {
        this.header.element.classList.remove(this.header.showClass);
        this.header.element.style.transform = `translate3d(0, -${this.header.height}px, 0)`;
    }

    _headerShow() {
        this.header.element.classList.add(this.header.showClass);
        this.header.element.style.transform = `translate3d(0, 0, 0)`;
    }
}

const menuBurger = () => {
    const mainMenu = document.querySelector('.main-menu');
    const headerElement = document.querySelector('.header');
    const body = document.querySelector('body');

    document.querySelectorAll('.j-burger').forEach((burger) => {
        burger.addEventListener('click', () => {
            burger.classList.toggle('is-active');
            mainMenu.classList.toggle('main-menu--open');
            headerElement.classList.toggle('header--open');
            body.classList.toggle('no-scroll');
        });
    });
};

const menuParent = () => {
    document.querySelectorAll('.touch .j-parent').forEach((parentElement) => {
        parentElement.addEventListener('click', () => {
            parentElement.classList.toggle('is-open');
            parentElement.parentElement.querySelector('.menu-list__child').classList.toggle('is-open');
        });
    });
};

export { Header, menuBurger, menuParent };
