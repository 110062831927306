import { EffectCreative, Mousewheel, Navigation, Pagination} from 'swiper/modules';
import Swiper from 'swiper';

const frontSlider = () => {
    const sliders = document.querySelectorAll('.j-slider-main');

    if (!sliders) {
        return;
    }

    const slidersCache = [];

    if (sliders.length > 0) {
        sliders.forEach((item) => {
            const slider = new Swiper(item, {
                modules      : [Navigation, Mousewheel, Pagination, EffectCreative],
                loop         : true,
                slidesPerView: 1,
                autoplay: 1500,
                loopedSlides: 3,
                spaceBetween : 100,
                effect: 'creative',
                creativeEffect: {
                    prev: {
                        shadow: true,
                        translate: ['-20%', 0, -1]
                    },
                    next: {translate: ['100%', 0, 0]}
                },
                pagination   : {
                    el: item.parentElement.querySelector('.pagination'),
                    type: 'fraction'
                },
                navigation: {
                    prevEl: '.vacancy-navigation .prev',
                    nextEl: '.vacancy-navigation .next'
                },
                mousewheel: {
                    forceToAxis: true,
                    releaseOnEdges: false
                }
            });

            slidersCache.push(slider);
        });
    }
};

export default frontSlider;
