const blogSection = () => {

    document.querySelectorAll('.j-cases-type').forEach((clcker) => {
        clcker.addEventListener('click', () => {
            let targetNumber = clcker.dataset.tag;

            document.querySelectorAll('.j-cases-type').forEach((clckerOff) => {
                clckerOff.classList.remove('actived');
            });

            clcker.classList.add('actived');

            document.querySelectorAll('.blog-item').forEach((itemCases) => {
                itemCases.style.display = "none";
            });

            document.querySelectorAll(`[data-cases="${targetNumber}"]`).forEach((itemTarget) => {
                itemTarget.style.display = "block";
            });

            if (targetNumber == 0) {
                document.querySelectorAll('.blog-item').forEach((itemCases) => {
                    itemCases.style.display = "block";
                });
            }
        });
    });

};

export default blogSection;
