import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';

const isTouch = () => {
    return Boolean(
        'ontouchstart' in window ||
            (window.DocumentTouch && document instanceof window.DocumentTouch) ||
            navigator.msMaxTouchPoints > 0 ||
            navigator.maxTouchPoints
    );
};

// eslint-disable-next-line func-style
function debounce(func, timeout = 300) {
    let timer = null;

    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            // eslint-disable-next-line no-invalid-this
            func.apply(this, args);
        }, timeout);
    };
}

const throttle = (fun, time) => {
    let lastCall = null;

    return (args) => {
        const previousCall = lastCall;

        lastCall = Date.now();

        // eslint-disable-next-line no-undefined
        if (previousCall === undefined || lastCall - previousCall > time) {
            fun(args);
        }
    };
};

const fixFullHeight = () => {
    const vh = window.innerHeight * 0.01;

    document.documentElement.style.setProperty('--vh', `${vh}px`);
};

const checkEnableJS = () => {
    document.body.classList.remove('not-js');
};

const isInViewport = (element, offset = 0) => {
    const rect = element.getBoundingClientRect();
    const top = rect.top + offset;
    const left = rect.left + offset;
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    const windowWidth = window.innerWidth || document.documentElement.clientWidth;
    const belowViewport = 0;

    const verticalInView = top <= windowHeight && top + rect.height >= belowViewport;
    const horizontalInView = left <= windowWidth && left + rect.width >= belowViewport;

    return verticalInView && horizontalInView;
};

const toggleElement = (element, state = true) => {
    if (state === true) {
        element.style.height = 'auto';
        const height = `${element.clientHeight}px`;

        element.style.height = '0px';

        setTimeout(() => {
            element.style.height = height;
        }, 0);
    } else {
        element.style.height = '0px';
    }
};

const removeElement = (element) => {
    if (!element.parentNode) {
        return;
    }

    element.parentNode.removeChild(element);
};

const insertElement = (element, content) => {
    if (typeof content === 'string') {
        element.insertAdjacentHTML('beforeend', content);
    } else if (typeof content === 'object') {
        element.appendChild(content);
    }
};

const replaceElement = (currentElement, newElement) => {
    if (typeof newElement === 'string') {
        currentElement.insertAdjacentHTML('beforebegin', newElement);
    } else if (typeof newElement === 'object') {
        currentElement.insertAdjacentHTML('beforebegin', newElement);
    }

    removeElement(currentElement);
};

const bodyFixed = (element) => {
    disableBodyScroll(element, { reserveScrollBarGap: true });
};

const bodyUnFixed = () => {
    clearAllBodyScrollLocks();
};

const generateUid = () => {
    return (performance.now().toString(16) + Math.random().toString(16))
        .replace(/\./g, '')
        .substring(0, 6);
};

const uuidv4 = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => {
        // eslint-disable-next-line no-bitwise
        return (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16);
    });
};

const getParents = (el, compareSelector = '', parentSelector = document) => {
    const parents = [];
    let parent = el.parentNode;

    while (parent !== parentSelector) {
        const currentParent = parent;

        if (compareSelector.length > 0) {
            if (currentParent.classList.contains(compareSelector)) {
                parents.push(currentParent);
            }
        } else {
            parents.push(currentParent);
        }
        parent = currentParent.parentNode;
    }

    return parents;
};

// eslint-disable-next-line no-empty-function
const clickOutside = (selectors, outside = () => {}, inside = () => {}) => {
    if (!selectors) {
        return;
    }

    const elements = [];

    if (typeof selectors == 'string') {
        elements.push(Array.from(document.querySelectorAll(selectors)));
    } else {
        Array.from(selectors).forEach((selector) => {
            elements.push(Array.from(document.querySelectorAll(selector)));
        });
    }

    document.addEventListener('click', (event) => {
        let target = event.target;

        do {
            try {
                // eslint-disable-next-line no-loop-func
                elements.forEach((element) => {
                    if (element.indexOf(target) !== -1) {
                        inside();

                        // eslint-disable-next-line no-throw-literal
                        throw false;
                    }
                });
            } catch (error) {
                return;
            }

            target = target.parentNode;
        } while (target);

        outside();
    });
};

const isVisible = (element) => {
    return !(element.style.display === 'none');
};

const show = (element, type = 'block') => {
    element.style.display = type;
};

const hide = (element) => {
    element.style.display = 'none';
};

const getPageSizes = () => {
    return {
        width: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
        height: Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
    };
};

const getViewPort = () => {
    return {
        width: window.innerWidth,
        height: window.innerHeight
    };
};

const getScrollTop = () => {
    return (window.pageYOffset || document.scrollTop) - (document.clientTop || 0) || 0;
};

const removeClasses = ({ part, classNames = '', parent = document }) => {
    if (typeof part === 'object' && Array.isArray(part)) {
        part.forEach((element) => {
            removeClasses({
                part: element,
                classNames,
                parent
            });
        });
    } else if (typeof part === 'string') {
        const find = Array.from(parent.querySelectorAll(part));

        removeClasses({
            part: find,
            classNames,
            parent
        });
    } else {
        // eslint-disable-next-line no-lonely-if
        if (part.classList) {
            if (typeof classNames === 'object' && Array.isArray(classNames)) {
                classNames.forEach((className) => {
                    removeClasses({
                        part,
                        classNames:
                        className,
                        parent
                    });
                });
            } else {
                try {
                    part.classList.remove(classNames);
                } catch (error) {
                    console.warn(error.message);
                }
            }
        }
    }
};

const elementsFullHeight = (elements) => {
    let blocks = elements;

    if (typeof blocks === 'object') {
        blocks = Array.from(blocks);
    }

    const maxHeight = blocks.reduce((acc, item) => {
        if (item.scrollHeight >= acc) {
            // eslint-disable-next-line no-param-reassign
            acc = item.scrollHeight;
        }

        return acc;
    }, 0);

    blocks.forEach((block) => {
        block.style.height = `${maxHeight}px`;
    });
};

const disableHover = () => {
    document.addEventListener('scroll', () => {
        if (!document.body.classList.contains('disable-hover')) {
            document.body.classList.add('disable-hover');
        }
    }, { passive: true });

    document.addEventListener('scroll', debounce(() => {
        document.body.classList.remove('disable-hover');
    }, 100), { passive: true });
};

const slideUpDown = () => {
    window.slideUp = function(target, duration=500) {
        if (!target.classList.contains('in-progress')){
            let evt = new CustomEvent('slideUp', { 
                detail: target
            });

            window.dispatchEvent(evt);

            target.classList.add('in-progress');
            target.style.transitionProperty = 'height, margin, padding';
            target.style.transitionDuration = duration + 'ms';
            target.style.boxSizing = 'border-box';
            target.style.height = target.offsetHeight + 'px';
            target.offsetHeight;
            target.style.overflow = 'hidden';
            target.style.height = 0;
            target.style.paddingTop = 0;
            target.style.paddingBottom = 0;
            target.style.marginTop = 0;
            target.style.marginBottom = 0;
            window.setTimeout( () => {
                target.classList.remove('in-progress')
                target.style.display = 'none';
                target.style.removeProperty('height');
                target.style.removeProperty('padding-top');
                target.style.removeProperty('padding-bottom');
                target.style.removeProperty('margin-top');
                target.style.removeProperty('margin-bottom');
                target.style.removeProperty('overflow');
                target.style.removeProperty('transition-duration');
                target.style.removeProperty('transition-property');
            }, duration);
        }
    }
    
    window.slideDown = function(target, duration=500) {

        if (!target.classList.contains('in-progress')){
            let evt = new CustomEvent('slideDown', { 
                detail: target
            });

            window.dispatchEvent(evt);

            target.classList.add('in-progress');
            target.style.removeProperty('display');
            let display = window.getComputedStyle(target).display;
            if (display === 'none') display = 'block';
            target.style.display = display;
            let height = target.offsetHeight;
            target.style.overflow = 'hidden';
            target.style.height = 0;
            target.style.paddingTop = 0;
            target.style.paddingBottom = 0;
            target.style.marginTop = 0;
            target.style.marginBottom = 0;
            target.offsetHeight;
            target.style.boxSizing = 'border-box';
            target.style.transitionProperty = "height, margin, padding";
            target.style.transitionDuration = duration + 'ms';
            target.style.height = height + 'px';
            target.style.removeProperty('padding-top');
            target.style.removeProperty('padding-bottom');
            target.style.removeProperty('margin-top');
            target.style.removeProperty('margin-bottom');
            window.setTimeout( () => {
                target.classList.remove('in-progress')
                target.style.removeProperty('height');
                target.style.removeProperty('overflow');
                target.style.removeProperty('transition-duration');
                target.style.removeProperty('transition-property');
            }, duration);
        }
    }
    
    window.slideToggle = function(target, duration = 500) {
        if (!target.classList.contains('in-progress')){
            if (window.getComputedStyle(target).display === 'none') {
                slideDown(target, duration);
                return 'SLIDE-DOWN';
            } else {
                slideUp(target, duration);
                return 'SLIDE-UP';
            }
        }
    }
    
    document.body.addEventListener('click', function(e){

        if (e.target.closest('.slide-down-item')){
            let _this = e.target.closest('.slide-down-item');
            let slideDownTarget = _this.getAttribute('data-target');
            let result = '';
    
            if (slideDownTarget){
        
                let textActive = _this.getAttribute('data-text-active');
                let textDisable = _this.getAttribute('data-text-disable');
        
                slideDownTarget = document.getElementById(slideDownTarget);
                if (slideDownTarget) result = slideToggle(slideDownTarget);
        
                switch(result) {
                    case 'SLIDE-DOWN':
                        _this.classList.add('slide-down-item--active');
                        if (textActive) _this.innerText = textActive;
                    break;
                    case 'SLIDE-UP':
                        _this.classList.remove('slide-down-item--active');
                        if (textDisable) _this.innerText = textDisable;
                    break;
                }
            }
        }
    });
}

const customTabs = () => {

    document.body.addEventListener('click', function(e){
        if (e.target.closest('.tab-link')){
            let item = e.target.closest('.tab-link');
            if (!item.classList.contains('tab-link-active')) {
                let container = item.closest('.tab-links');
                let target = item.getAttribute('data-target');
                let activeItems;

                if (container) {
                    activeItems = container.querySelectorAll('.tab-link-active');
                    if (activeItems) activeItems.forEach(element => element.classList.remove('tab-link-active'));
                }
    
                item.classList.add('tab-link-active');
                if (target) target = document.getElementById(target);
                if (target) {
                    container = target.closest('.tab-containers');

                    if (container) {
                        activeItems = container.querySelectorAll('.tab-container-active');
                        if (activeItems) activeItems.forEach(element => element.classList.remove('tab-container-active'));
                    }

                    target.classList.add('tab-container-active');
                }
            }
        }
    });
}


export {
    isTouch,
    debounce,
    throttle,
    fixFullHeight,
    checkEnableJS,
    isInViewport,
    toggleElement,
    removeElement,
    insertElement,
    replaceElement,
    bodyFixed,
    bodyUnFixed,
    generateUid,
    uuidv4,
    getParents,
    clickOutside,
    isVisible,
    show,
    hide,
    getPageSizes,
    getViewPort,
    getScrollTop,
    removeClasses,
    elementsFullHeight,
    disableHover,
    slideUpDown,
    customTabs
};
