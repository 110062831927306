import { Mousewheel, Navigation } from 'swiper/modules';
import Swiper from 'swiper';

const mainDirections = () => {
    
    const showSlider = () => {
    
        // const sliders = document.querySelectorAll('.j-slider-directions');
        // let sliderDemos = null;

        // if (!sliders) {
        //     return;
        // }

        // const slidersCache = [];

        // if (sliders.length > 0) {
        //     sliders.forEach((item) => {
        //         sliderDemos = new Swiper(item, {
        //             modules      : [Navigation, Mousewheel],
        //             loop         : false,
        //             slidesPerView: 'auto',
        //             grabCursor   : false,
        //             spaceBetween : 16,
        //             navigation: {
        //                 prevEl: '.direction-navigation .prev',
        //                 nextEl: '.direction-navigation .next'
        //             },
        //             mousewheel: {
        //                 forceToAxis: true,
        //                 releaseOnEdges: false
        //             }
        //         });

        //         slidersCache.push(sliderDemos);
        //     });
        // }

        const demoElement = document.querySelector('.j-slider-directions');
        let sliderDemos = null;

        demoElement.querySelector('.swiper-wrapper').removeAttribute("style");

        const destroyChoose = () => {
            if (sliderDemos !== null) {
                sliderDemos.destroy(true, true);
            }
            sliderDemos = null;
        };

        const initChoose = () => {

            destroyChoose();

            if (sliderDemos === null) {
                sliderDemos = new Swiper(demoElement, {
                    modules      : [Navigation, Mousewheel],
                    loop         : false,
                    slidesPerView: 'auto',
                    initialSlide : 0,
                    grabCursor   : false,
                    spaceBetween : 16,
                    navigation: {
                        prevEl: '.direction-navigation .prev',
                        nextEl: '.direction-navigation .next'
                    },
                    mousewheel: {
                        forceToAxis: true,
                        releaseOnEdges: false
                    }
                });
                demoElement.classList.remove('slider-choose--column');
            }

            sliderDemos.update();
        };

        
        initChoose();
    }

    showSlider();

    document.querySelectorAll('.j-components-type-cases').forEach((clcker) => {
        clcker.addEventListener('click', () => {
            let targetNumber = clcker.dataset.target;

            document.querySelectorAll('.j-components-type-cases').forEach((clckerOff) => {
                clckerOff.classList.remove('actived');
            });

            clcker.classList.add('actived');

            // document.querySelectorAll('.direction-slide').forEach((itemCases) => {
            //     itemCases.style.display = "none";
            // });

            // document.querySelectorAll(`[data-cases="${targetNumber}"]`).forEach((itemTarget) => {
            //     itemTarget.style.display = "block";
            // });

            // if (targetNumber == 0) {
            //     document.querySelectorAll('.direction-slide').forEach((itemCases) => {
            //         itemCases.style.display = "block";
            //     });
            // }

            showSlider();
        });
    });

};

export default mainDirections;
