// import 'lazysizes';
import { customTabs, debounce, disableHover, fixFullHeight, slideUpDown } from './utils';
import { Header, menuBurger, menuParent } from '../../components/header';
// import GraphModal from 'graph-modal/src/graph-modal.js';
import svg4everybody from 'svg4everybody';
import { Fancybox } from "@fancyapps/ui";
import tippy from 'tippy.js';


const appStart = () => {
    slideUpDown();
    customTabs();
    fixFullHeight();

    ['resize', 'orientationchange'].forEach((event) => {
        window.addEventListener(event, debounce(fixFullHeight, 20));
    });

    disableHover();
    menuBurger();
    menuParent();
    // new GraphModal();

    Fancybox.bind("[data-fancybox]", {});

    document.querySelectorAll('.j-tippy-icon').forEach((tippyParent) => {
        tippy(tippyParent, {
            content: tippyParent.querySelector('.j-tippy-text').innerHTML,
            arrow: false,
            interactive: false,
            placement: 'top',
            allowHTML: true,
            theme: 'active',
        });
    });

    new Header().init();
    svg4everybody();
};

export default appStart;
