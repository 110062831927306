import { Mousewheel, Navigation, Pagination, Thumbs} from 'swiper/modules';
import Swiper from 'swiper';

const aboutSlider = () => {
    const sliders = document.querySelectorAll('.j-slider-about');

    if (!sliders) {
        return;
    }

    let swiper = new Swiper(".j-slider-about-thumbs", {
        spaceBetween: 8,
        slidesPerView: 2.2,
        freeMode: false,
        watchSlidesProgress: true,
        breakpoints: {
            450: {
                slidesPerView: 5.1,
            },
            768: {
                slidesPerView: 4.2,
                spaceBetween: 8,
            },
            992: {
                slidesPerView: 5,
                spaceBetween: 8,
            }
        }
    });

    const slidersCache = [];

    if (sliders.length > 0) {
        sliders.forEach((item) => {
            const slider = new Swiper(item, {
                modules      : [Navigation, Mousewheel, Pagination, Thumbs],
                loop         : true,
                slidesPerView: 1,
                autoplay: 1500,
                autoHeight: true,
                loopedSlides: 1,
                spaceBetween : 100,
                navigation: {
                    prevEl: '.about-navigation .prev',
                    nextEl: '.about-navigation .next'
                },
                thumbs: {
                    swiper: swiper,
                },
                mousewheel: {
                    forceToAxis: true,
                    releaseOnEdges: false
                }
            });

            slidersCache.push(slider);
        });
    }
};

export default aboutSlider;
